import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useAppState } from "../AppContext";
import { useNavigate } from "react-router-dom";
import { SearchNcr } from "../Fetcher/searchNcr";
import { ToastContainer, toast } from "react-toastify";
import {
  Spinner,
  Button,
  Dialog,
  DialogHeader,
  DialogFooter,
  Tooltip
} from "@material-tailwind/react";

const Trash = () => {
  const { state, dispatch } = useAppState();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [ncr, setNcr] = useState(null);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    fetchTrashList();
  }, []);  

  useEffect(() => {
    const itemsPerPage = 15;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const temp = data?.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentData(temp);
  }, [currentPage, data]);

  const fetchTrashList = async () => {
    try {
      const res = await fetch(
        `https://backendncr.ampectech.com/api/soft-deleted-ncrs`
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      setData(data.softDeletedNCRs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleView = async (ncr) => {
    const res = await SearchNcr(ncr, true);
    if (res) {
      dispatch({ type: "SET", payload: res });
      dispatch({ type: "DISABLE", payload: true });
      dispatch({ type: "EDITBUTTON", payload: true });
      dispatch({ type: "RESTORE", payload: true });
      navigate("/");
    }
  };

  const handleRestore = async () => {
    try {
      const res = await fetch(
        `https://backendncr.ampectech.com/api/restore-ncr/${ncr}`,
        {
          method: "PUT",
        }
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      toast.success(data.message);
      fetchTrashList();
      setOpen(!open);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const parmanentDelete = async () => {
    try {
      const res = await fetch(
        `https://backendncr.ampectech.com/api/permanently-delete-ncr/${ncr}`,
        {
          method: "DELETE",
        }
      );
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await res.json();
      toast.success(data.message);
      setOpenDelete(!openDelete);
      fetchTrashList();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className="mt-16 container mx-auto px-10">
      <p className="font-bold text-center py-6 text-4xl">Trash</p>
      <div className="text-center text-sm">
        <div className="flex justify-between mb-2 bg-black text-white font-semibold text-center ">
          <div className="flex-1 p-1 py-2">Serial</div>
          <div className="flex-1 p-1 py-2">NCR Number</div>
          <div className="flex-1 p-1 py-2">Created at</div>
          <div className="flex-1 p-1 py-2">Deleted at</div>
          <div className="flex-1 p-1 py-2">Action</div>
        </div>
        {currentData &&
          currentData.map((item, index) => {
            return (
              <div
                key={item.ncr}
                className="flex justify-between border mb-1 bg-white shadow items-center  py-0.5 hover:bg-gray-100 hover:border-blue-400 duration-150"
              >
                <div className="flex-1 p-1">
                  {index + 1 + (currentPage - 1) * 15}
                </div>
                <div className="flex-1 p-1">{`${item.type}-${item.ncr}` || ''}</div>
                <div className="flex-1 p-1">
                  {item.created_at?.split("T")[0] || ''}
                </div>
                <div className="flex-1 p-1">
                  {item.deleted_at?.split("T")[0] || ''}
                </div>
                <div className="flex-1">
                  {/* View Button */}
                  <Tooltip content="View" className="bg-gray-200 text-black">
                  <button
                    className=" hover:text-black text-black/70 p-1 hover:bg-black/10 rounded-full"
                    onClick={() => handleView(item.ncr)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"
                      />
                    </svg>
                  </button>
                  </Tooltip>
                  {/* Restore button */}
                  <Tooltip content="Restore" className="bg-gray-200 text-black">
                  <button
                    className="text-green-400 hover:text-green-600 p-1 hover:bg-black/10 rounded-full"
                    onClick={() => {
                      setOpen(!open);
                      setNcr(item.ncr);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M13.25 3a9.003 9.003 0 0 0-9.26 9H2.2c-.45 0-.67.54-.35.85l2.79 2.8c.2.2.51.2.71 0l2.79-2.8c.32-.31.09-.85-.35-.85h-1.8c0-3.9 3.18-7.05 7.1-7c3.72.05 6.85 3.18 6.9 6.9c.05 3.91-3.1 7.1-7 7.1c-1.61 0-3.1-.55-4.28-1.48a.994.994 0 0 0-1.32.08c-.42.43-.39 1.13.08 1.5a8.931 8.931 0 0 0 5.52 1.9c5.05 0 9.14-4.17 9-9.26c-.13-4.69-4.05-8.61-8.74-8.74zm-.51 5c-.41 0-.75.34-.75.75v3.68c0 .35.19.68.49.86l3.12 1.85c.36.21.82.09 1.03-.26c.21-.36.09-.82-.26-1.03l-2.88-1.71v-3.4c0-.4-.33-.74-.75-.74z"
                      />
                    </svg>
                  </button>
                  </Tooltip>
                  {/* Permanent delete button */}
                  <Tooltip content="Delete" className="bg-gray-200 text-black">
                  <button
                    className="text-red-300 hover:text-red-600 p-1 hover:bg-black/10 rounded-full"
                    onClick={() => {
                      setOpenDelete(!openDelete);
                      setNcr(item.ncr);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M7 21q-.825 0-1.413-.588T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.588 1.413T17 21H7Zm2-4h2V8H9v9Zm4 0h2V8h-2v9Z"
                      />
                    </svg>
                  </button>
                  </Tooltip>
                </div>
              </div>
            );
          })}
      </div>
      <Dialog open={open} handler={() => setOpen(!open)}>
        <DialogHeader>
          Are you sure you want to restore NCR: {ncr}?
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpen(!open)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleRestore}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog open={openDelete} handler={() => setOpenDelete(!openDelete)}>
        <DialogHeader>
          Are you sure you want to delete this NCR: {ncr} permanently?
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => setOpenDelete(!openDelete)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={parmanentDelete}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <div
        className={`flex justify-center my-10 ${
          data?.length > 15 ? "" : "hidden"
        }`}
      >
        <ReactPaginate
          breakLabel={"..."}
          pageCount={Math.ceil((data?.length || 0) / 15)}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          previousLabel="Previous"
          nextLabel="Next"
          previousLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
          nextLinkClassName="px-2 py-px rounded-sm bg-blue-500 text-white"
          activeClassName="bg-blue-500 text-white"
          pageLinkClassName="p-2"
          className="flex gap-2"
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Trash;
