export async function SearchNcr(ncr_no, fromTrash) {
  
  try {
    const res = await fetch(
      fromTrash ? `https://backendncr.ampectech.com/api/ncrDeleted/${ncr_no}/data` :
      `https://backendncr.ampectech.com/api/ncr/${ncr_no}/data`
    );
    if (!res.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await res.json();
    
    if(data.data[0].ncr === null) {
      alert("No NCR form found with that number");
      return false;
    }
    const nature_of_problem = JSON.parse(data.data[0].nature_of_problem);
    data.data[0].nature_of_problem = JSON.parse(nature_of_problem);
    const consequence = JSON.parse(data.data[0].consequence);
    data.data[0].consequence = JSON.parse(consequence);
    
    const hazard = JSON.parse(data.data[0].hazard);
    data.data[0].hazard = JSON.parse(hazard);
    const item = JSON.parse(data.data[0].item);
    data.data[0].item = JSON.parse(item);
    const item2 = JSON.parse(data.data[0].item2);
    data.data[0].item2 = JSON.parse(item2);
    const likelihood = JSON.parse(data.data[0].likelihood);
    data.data[0].likelihood = JSON.parse(likelihood);
    const percentage = JSON.parse(data.data[0].percentage);
    data.data[0].percentage = JSON.parse(percentage);
    const possible_cause = JSON.parse(data.data[0].possible_cause);
    data.data[0].possible_cause = JSON.parse(possible_cause);
    const risk = JSON.parse(data.data[0].risk);
    data.data[0].risk = JSON.parse(risk);
    const risk_control = JSON.parse(data.data[0].risk_control);
    data.data[0].risk_control = JSON.parse(risk_control);
    const risk_level = JSON.parse(data.data[0].risk_level);
    data.data[0].risk_level = JSON.parse(risk_level);

    if (data.data[0].files === 'null' || data.data[0].files === null) {
      data.data[0].files = [];
    }
    else{
      const jsonStringFiles = data.data[0].files;
      const unescapedStringFiles = jsonStringFiles.replace(/\\/g, "");
      data.data[0].files = JSON.parse(unescapedStringFiles);
    }

    const risk_rating = JSON.parse(data.data[0].risk_rating);
    data.data[0].risk_rating = JSON.parse(risk_rating);
    const root = JSON.parse(data.data[0].root);
    data.data[0].root = JSON.parse(root);

    return data.data[0];
  } catch (error) {
    alert("No NCR form found with that number");
    console.error("Error fetching data:", error);
  }
}
